.term-container {
  display: flex;
  flex-direction: column;
  /* width: 80vw; */
  padding-top: 7rem;
  justify-content: center;
  align-items: center;
}
.term-sub-container {
  display: flex;
}
.select {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgb(131, 129, 129);
  padding: 0.9rem 1.2rem;
  width: 21rem;
}
.selects {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgb(131, 129, 129);
  padding: 0.9rem 1.2rem;
  width: 20vh;
}
.price-tag {
  width: 10vw;
}

.botton {
  width: 18rem;
  height: 3rem;
  position: relative;
  border-radius: 2rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.botton .order {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  margin-top: 0.7rem;
  /* padding-top: -0.2rem; */
}

@media screen and (width < 768px) {
  .term-sub-container {
    flex-direction: column;
  }
  .select {
    width: 21rem;
  }
  .selects {
    width: 40vw;
  }
  .price-tag {
    width: 33vw;
    margin-left: 0.3rem;
  }
}
