.contact-container {
  padding-top: 7rem;
  /* padding: 15vw; */
  justify-content: center;
  display: flex;
  align-items: center;
}
.contact-img {
  width: 30rem;
  height: 30rem;
}

@media screen and (width < 768px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-img {
    width: 15rem;
    height: 15rem;
  }
}
