/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap"); */

.date-input {
  position: relative;
  width: 100%;
  z-index: 0;
  margin: 0.8rem 0.8rem 0.8rem 0.8rem;
}

.date-input input::placeholder {
  opacity: 0;
}

.date-input input {
  width: 8rem;
  height: 3rem;
  font-size: 0.8rem;
  padding: 10px;
  outline: none;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
}

.date-input label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8rem;
  color: black;
  letter-spacing: 1px;
  transition: 0.1s;
  font-weight: 400;
}

.date-input input:focus + label,
.date-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.6rem;
  color: hsl(39, 77%, 36%);
  background: #fff;
  padding: 3px;
  border-radius: 8px;
}

.date-input input:focus {
  outline: 1.2px solid hsl(39, 77%, 36%);
}
