/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap"); */

.search-input {
  position: relative;
  width: 100%;
  z-index: 0;
  margin: 0.8rem 0.8rem 0.8rem 0.8rem;
}

.search-input input::placeholder {
  opacity: 0;
}

.search-input input {
  width: 45vw;
  height: 3rem;
  font-size: 0.8rem;
  padding: 10px;
  outline: none;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
}

.search-input label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8rem;
  color: black;
  letter-spacing: 1px;
  transition: 0.1s;
  font-weight: 400;
}

.search-input input:focus + label,
.search-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.6rem;
  color: hsl(39, 77%, 53%);
  background: #fff;
  padding: 3px;
  border-radius: 8px;
}

.search-input input:focus {
  outline: 1.2px solid hsl(39, 77%, 73%);
}
.search-input .pass-icon {
  position: absolute;
  font-size: 1.1rem;
  top: 30%;
  left: 90%;
}
@media screen and (width < 321px) {
  .search-input input {
    width: 90vw;
  }
}
@media screen and (width < 768px) {
  .search-input {
    margin: 0.5rem 0rem 0.5rem 0rem;
  }
  .search-input input {
    width: 70vw;
    margin-left: -3rem;
  }
}
