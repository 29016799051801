* {
  -webkit-tap-highlight-color: transparent;
}
.project_sub_title {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: 0;
  border-radius: 2rem;
}
.project_sub_title div {
  z-index: 1;
  position: relative;
}
.co {
  position: relative;
}
.img-botton {
  width: 8rem;
  height: 3rem;
  position: relative;
  border-radius: 2rem;
  margin-top: 1rem;
}
.blur-botton {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  background-color: rgba(0, 0, 0, 0.452);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.blur-botton:hover {
  background-color: rgba(0, 0, 0, 0.575);
}
.img-botton .order {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  padding-top: 0.8rem;
}
.con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem calc(0.15 * 100vw);
}
@media screen and (width < 768px) {
  .con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem calc(0.04 * 100vw);
  }
}
